// const API_URL = 'http://127.0.0.1:8000';
const API_URL = 'https://api.editorialbeca.com';

export const APP_APIS = {
    url: API_URL,
    //USER
    login: `${API_URL}/api/login`,//method:post
    loginApp: `${API_URL}/api/login-app`,//method:post
    register: `${API_URL}/api/register`,//method:post
    logout: `${API_URL}/api/logout`,//method:post

    //CODE
    codes: `${API_URL}/api/codes`,//method:post
    code_register: `${API_URL}/api/code/register`,//method:post
    code_resources_assign: `${API_URL}/api/code/resource/assign`,//method:post
    
    //RESOURCE
    resources: `${API_URL}/api/resources/app`,//method:post
    resource_register: `${API_URL}/api/resource/register`,//method:post
    resource_assings: `${API_URL}/api/resource/assings`,//method:post
    
    profile: `${API_URL}/api/user-profile`,//method:get
    profileApp: `${API_URL}/api/profile-app`,//method:post
    virtualbooks: `${API_URL}/api/virtualbooks`,//method:get
    virtualbook_page: `${API_URL}/api/virtualbook/page/`,//method:get
    minedu: `${API_URL}/api/minedu`,//method:post

    departmentso: `${API_URL}/api/departmentso`,//method:get
    provinceso: `${API_URL}/api/provinceso/`,//method:get
    districtso: `${API_URL}/api/districtso/`,//method:get
}