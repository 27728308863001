export const APP_CONFIG = {
    logo: '../assets/images/logo.png',

    mimeTypes: {
        'application/msword': {icon: '../assets/icon/files-extensions/doc.png', extension: '.doc'},
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {icon: '../assets/icon/files-extensions/docx.png', extension: '.docx'},
        'image/jpeg': {icon: '../assets/icon/files-extensions/jpg.png', extension: '.jpg'},
        'application/pdf': {icon: '../assets/icon/files-extensions/pdf.png', extension: '.pdf'},
        'image/png': {icon: '../assets/icon/files-extensions/png.png', extension: '.png'},
        'application/vnd.ms-powerpoint': {icon: '../assets/icon/files-extensions/ppt.png', extension: '.ppt'},
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': {icon: '../assets/icon/files-extensions/ppt.png', extension: '.pptx'},
        'application/vnd.rar': {icon: '../assets/icon/files-extensions/rar.png', extension: '.rar'},
        'application/vnd.ms-excel': {icon: '../assets/icon/files-extensions/xls.png', extension: '.xls'},
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {icon: '../assets/icon/files-extensions/xlsx.png', extension: '.xlsx'},
        'application/zip': {icon: '../assets/icon/files-extensions/zip.png', extension: '.zip'},
        'generic': {icon: '../assets/icon/files-extensions/generic.png', extension: ''},
    },

    // COMBOS
    levels: [
        {id: 'Inicial', label: 'Inicial'},
        {id: 'Primaria', label: 'Primaria'},
        {id: 'Secundaria', label: 'Secundaria'}
    ],
    grades: [
        {id: 'Primero', label: 'Primero'},
        {id: 'Segundo', label: 'Segundo'},
        {id: 'Tercero', label: 'Tercero'},
        {id: 'Cuarto', label: 'Cuarto'},
        {id: 'Quinto', label: 'Quinto'},
        {id: 'Sexto', label: 'Sexto'}
    ],
    courses: [
        {id: 'Matemática', label: 'Matemática'},
        {id: 'Comunicación', label: 'Comunicación'}
    ],
    typeResources: [
        {id: 'google_drive', label: 'Google Drive (link)', icon: '../assets/icon/socials/google-drive.png'},
        {id: 'google_classroom', label: 'Google Classroom (link)', icon: '../assets/icon/socials/google-classroom.png'},
        {id: 'post', label: 'Información (link)', icon: '../assets/icon/socials/link-share.png'},
        {id: 'file', label: 'Archivo', icon: ''}
    ],
    sections: [
        {id: 'Única', label: 'Única'},
        {id: 'A', label: 'A'},
        {id: 'B', label: 'B'},
        {id: 'C', label: 'C'},
        {id: 'D', label: 'D'},
        {id: 'E', label: 'E'},
        {id: 'F', label: 'F'},
        {id: 'G', label: 'G'},
        {id: 'H', label: 'H'},
        {id: 'I', label: 'I'},
        {id: 'J', label: 'J'},
        {id: 'K', label: 'K'},
        {id: 'L', label: 'L'},
        {id: 'M', label: 'M'},
        {id: 'N', label: 'N'},
        {id: 'Ñ', label: 'Ñ'},
        {id: 'O', label: 'O'},
        {id: 'P', label: 'P'},
        {id: 'Q', label: 'Q'},
        {id: 'R', label: 'R'},
        {id: 'S', label: 'S'},
        {id: 'T', label: 'T'},
        {id: 'U', label: 'U'},
        {id: 'V', label: 'V'},
        {id: 'W', label: 'W'},
        {id: 'X', label: 'X'},
        {id: 'Y', label: 'Y'},
        {id: 'Z', label: 'Z'}
    ],

    // DB TABLES
    dbName: 'edibeca_docente.db',
    dbLocation: 'default',
    tables: [
        ['CREATE TABLE IF NOT EXISTS users (id integer PRIMARY KEY AUTOINCREMENT NOT NULL, uuid VARCHAR(255) NOT NULL)']
    ]
}